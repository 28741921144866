

























import { Component, Vue } from 'vue-property-decorator'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { IOptions } from '@/app/infrastructures/misc/Constants/manageCourierDedicated'

@Component({
  components: {
    Tabs,
    Button
  },
})
export default class ManageCourierDedicatedListPage extends Vue {
  currentTab = 0

  created(): void {
    if (this.$route.name !== this.getTabRoute(0)) {
      this.$router.push({ name: this.getTabRoute(0) })
    }
  }

  get itemTabs(): IOptions[] {
    const items = [
      { label: 'Daftar Area', value: 0 },
      { label: 'Daftar Kurir Dedicated', value: 1 },
    ]
    return items
  }

  private onAdd(): void {
    this.$router.push({ name: 'ManageCourierDedicatedCreatePage' })
  }

  private onTabsClick(tab: number): void {
    this.currentTab = tab
    this.itemTabs.filter((item) => {
      if (item.value === this.currentTab) this.$router.push({ name: this.getTabRoute(item.value) })
    })
  }

  private getTabRoute(key: number): string {
    if (key === 0) { return 'AreaDedicatedListPage' }
    if (key === 1) { return 'CourierDedicatedListPage' }
    return ''
  }
}
